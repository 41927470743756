import React, { useState, useEffect } from "react";
import { useNavigate, useRoutes} from "react-router-dom";
import { routes } from "../components/routes";


import { motion } from "framer-motion";
import { toast } from "react-toastify";

import { NavigationMenu } from "./navigationMenu.js";
import { Footer } from "./footer.js";

import "../css/navigation.css";
import "../css/footer.css";

import NavbarLogo from "../images/Final Doyen Games Logo Navbar Small.png";
import NavbarSymbol from "../images/Final Doyen Games Symbol Navbar Small.png";


export function Navigation(props) {

    const routing = useRoutes(routes({}))

    const [viewBox, setViewBox] = useState({width: window.innerWidth, height: window.innerHeight});

    function handleDetectSize(){
        let width = window.innerWidth;
        let height = window.innerHeight;
        setViewBox({width: width, height: height});
    }
    useEffect(()=>{
        window.addEventListener(`resize`, handleDetectSize)

        return()=>{
        window.removeEventListener(`resize`, handleDetectSize)
        }
    }, [viewBox]);

    const navigate = new useNavigate();
    function handleNavigation(destination){
        let type = destination.split("https://");
        if (type[1]){
            window.open(destination);
            return;
        } else {
            navigate("/" + destination);
            setTimeout(()=>{
                let url = window.location.href;
                let urlSplit = url.split("#");
                if (urlSplit[1]){
                    window.location.href = urlSplit[0] + "#" + urlSplit[1];

                } else {
                    window.scrollTo(0, 0);
                }
            },5);
        }
    }

    const navbarLinks = [
        {name:"About", nav:"#about", img: require('../images/NavbarAbout.svg').default},
        {name:"Projects", nav:"#projects", img: require('../images/NavbarProjects.svg').default},
        {name:"Contact", nav:"#contact", img: require('../images/NavbarContact.svg').default}
    ]
    return (
    <>

    <div className="navbar-container">
        <div className="navbar">

            <div className="navbar-logo" style={{marginLeft:"16px"}}
                onClick={()=>handleNavigation("")}>
                    <div className="navbar-links">{viewBox.width >= 1000 ? "<DoyenGames/>" : <div style={{display:"flex", flexDirection:"row"}}><div>{"<D"}</div><div style={{marginLeft:"-4px"}}>{"G/>"}</div></div>}</div>

            </div>
            {viewBox.width >= 960 ?
            <>

            <div className="navbar-links-row">
                {navbarLinks.map((link, a)=>(
                    <div className="navbar-logo" onClick={()=>handleNavigation(`${link.nav}`)} key={a} index={a}>
                        <div className="navbar-links">{"<"}{link.name}{"/>"}</div>
                    </div>
                ))}
            </div>
            <div className="navbar-logo" style={{marginRight:"16px"}} onClick={()=>window.open("https://github.com/doyen-games")}>
                <div className="navbar-links">{"<Github/>"}</div>
            </div>
            </> :

            <NavigationMenu navbarLinks={navbarLinks} handleNavigation={handleNavigation}/>

            }
        </div>
    </div>
    <div style={{position:"absolute", top:"124px", left:"0px"}}>
    {routing}
    <Footer handleNavigation={handleNavigation}/>
    </div>

    </>)
}