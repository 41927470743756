import React, { useEffect, useState } from "react";
import { Navigation } from "./components/navigation.js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import './css/app.css';

export function App() {
  return (
    <div>
      <ToastContainer
      style={{ width: "40vw", minWidth:"250px" }}
      position="top-left"
      autoClose={2500}
      hideProgressBar={true}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
      />
      <Navigation />

    </div>
  );
}

