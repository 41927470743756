import React, { useState, useEffect, useRef, Children } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { motion } from "framer-motion";
import { Name } from "@wharfkit/antelope";

import "../css/app.css";
import "../css/pages.css";
import "../css/navigation.css";

export function Landing(props){
    const { } = props;

    const [viewBox, setViewBox] = useState({width: window.innerWidth, height: window.innerHeight});

    function handleDetectSize(){
        let width = window.innerWidth;
        let height = window.innerHeight;
        setViewBox({width: width, height: height});
    }
    useEffect(()=>{
        window.addEventListener(`resize`, handleDetectSize)

        return()=>{
        window.removeEventListener(`resize`, handleDetectSize)
        }
    }, [viewBox]);

    const [entered, setEntered] = useState(false);

    useEffect(()=>{
        setEntered(true);
    },[]);

    //https://forms.gle/rKrdJVpzfvgXqmmt7

    return(<>
        <div className="page-container">
            <div className="page-content-container" style={{minHeight:"80vh"}}>
                <div className="page-content">
                    <motion.div  initial={{opacity:0}} animate={{opacity: entered ? [0,1] : 0}} transition={{delay:4.5, duration:1.5, ease:"linear"}}
                         className="page-content-large-header" style={{margin:"20vh 0px 10vh 0px"}}>
                        {"<DoyenGames/>"}
                    </motion.div>
                    <motion.div initial={{opacity:0}} animate={{opacity: entered ? [0,1] : 0}} transition={{delay:0.5, duration:1.5, ease:"linear"}}
                        className="page-content-header">
                        Building the future
                    </motion.div>
                    <motion.div initial={{opacity:0}} animate={{opacity: entered ? [0,1] : 0}} transition={{delay:2.5, duration:1.5, ease:"linear"}}
                        className="page-content-header">
                        of Web3 Gaming
                    </motion.div>

                </div>
            </div>
            <div className="page-content-container" style={{minHeight:"100vh", margin:"40vh 0 20vh 0"}} id="about">
                <motion.div className="page-content" initial={{opacity:0}} animate={{opacity: entered ? [0,1] : 0}} transition={{delay:0.5, duration:1.5, ease:"linear"}}>
                    <div className="page-content-header" style={{marginTop:"20vh"}}>
                        What is our Mission?
                    </div>
                    <div className="page-content-box" style={{marginTop:"50vh", backgroundColor:"#ffffff00"}}>
                            <div className="page-content-text" style={{textAlign:"center", fontSize:"clamp(18px, 2vh, 2vw)", minWidth:"100%", fontWeight:"500", fontFamily:"Roboto", borderRadius:"150px"}}>
                                <div style={{padding:"0.5rem"}}>
                                build dope shit
                                <br/><br/>

                                </div>
                            </div>
                        </div>
                </motion.div>
            </div>

            <div className="page-content-container" style={{minHeight:"100vh"}} id="projects">
                <motion.div className="page-content" initial={{opacity:0}} animate={{opacity: entered ? [0,1] : 0}} transition={{delay:0.5, duration:1.5, ease:"linear"}}>
                    <div style={{marginTop:"20vh", minHeight:"4px"}}/>
                    <div
                        className="page-content-header" style={viewBox.width > 1000 ? {marginBottom:"120px"} : {marginBottom:"0rem"}}>
                        Projects
                    </div>
                    <div className="page-content-box-container" id="terraformers">
                        {viewBox.width > 1000 ?
                        <div className="page-content-box">
                            <div className="page-content-text" style={{textAlign:"left", fontSize:"clamp(24px, 2.66vh, 2.66vw)", minWidth:"100%", marginLeft:"32px", fontWeight:"600"}}>
                                Collect and trade Lands
                                <br/>
                                <br/>Hire Mercenaries
                                <br/>
                                <br/>Explore the Many Worlds
                                <div style={{textAlign:"left", fontSize:"clamp(18px, 2vh, 2vw)", minWidth:"100%",fontWeight:"500", fontFamily:"Roboto"}}>
                                    <br/><br/>
                                    Set in a dystopian, sci-fi fantasy universe, we deliver a unique blockchain gaming experience in our free-to-play Explorations game mode.
                                    <br/>
                                    <br/>Send out Mercenaries to Explore procedurally generated solar systems, fully kitted with an RPG game engine & filled to the brim with loot; all entirely on-chain.
                                    <br/>
                                    <br/>Travel through Space & Time, navigating Planets to find precious resources, rare NFTs & more as we continue development.
                                    <br/>
                                    <br/>Available only on WAX.
                                </div>
                            </div>
                        </div>
                        : ""}
                        <div className="page-content-box-banner" style={{backgroundImage:`url(${require('../images/TFMWSpace.png')})`}}>
                            <div className="page-content-box-banner-box" >
                                <div className="page-content-text" style={{fontSize:"clamp(32px, 3.66vh, 3.66vw)"}}>
                                    Terraformers,
                                    <br/>Many Worlds
                                </div>
                                <div style={{paddingBlock:"1.5rem"}}/>
                                <div className="page-content-text" style={{fontSize:"clamp(18px, 2vh, 2vw)", letterSpacing:"1vw", backdropFilter:"blur(1px)", minWidth:"200px", maxWidth:"65%"}}>The Many Worlds Await
                                </div>
                                <div style={{paddingBlock:"1.5rem"}}/>
                                <div className="navbar-logo" onClick={()=>window.open("https://tfmw.gg")}>
                                    <div className="navbar-links">Play Now</div>
                                </div>
                                {viewBox.width < 1000 ?
                                    <div className="page-content-box" style={{minWidth: "326px", marginTop:"3rem"}}>
                                        <div className="page-content-text" style={{textAlign:"center", fontSize:"clamp(22px, 2.4vh, 2.4vw)", fontWeight:"600", backdropFilter:"blur(1px)"}}>
                                            <div style={{padding:"0.3rem"}}>
                                            Collect and trade Lands
                                            <br/>
                                            <br/>Hire Mercenaries
                                            <br/>
                                            <br/>Explore the Many Worlds
                                            <div style={{textAlign:"left", fontSize:"clamp(18px, 2vh, 2vw)", minWidth:"100%",fontWeight:"500", fontFamily:"Roboto", padding:"0.5rem"}}>
                                                <br/><br/>
                                                Set in a dystopian, sci-fi fantasy universe, we deliver a unique blockchain gaming experience in our free-to-play Explorations game mode.
                                                <br/>
                                                <br/>Send out Mercenaries to Explore procedurally generated solar systems, fully kitted with an RPG game engine & filled to the brim with loot; all entirely on-chain.
                                                <br/>
                                                <br/>Travel through Space & Time, navigating Planets to find precious resources, rare NFTs & more as we continue development.
                                            </div>
                                        </div>
                                    </div>
                                </div>:""}
                            </div>
                        </div>
                    </div>
                    {/* {viewBox.width > 1000 && <div style={{marginBottom:"80px"}}/>}
                    <div className="page-content-box-container2" id="doyenacore">
                        {viewBox.width > 1000 ?
                        <div className="page-content-box2">
                            <div className="page-content-text" style={{textAlign:"left", fontSize:"clamp(24px, 2.66vh, 2.66vw)", minWidth:"100%", marginLeft:"32px", fontWeight:"600"}}>
                                Self Custodial Deployment
                                <br/>
                                <br/>Native Support for Socketing NFTs
                                <br/>
                                <br/>Generative NFTs for less than $0.01
                                <div style={{textAlign:"left", fontSize:"clamp(18px, 2vh, 2vw)", minWidth:"100%",fontWeight:"500", fontFamily:"Roboto"}}>
                                    <br/><br/>
                                    DoyenaCore is an open-source asset standard tailored for Web3 game studios seeking a flexible & feature rich solution to on-chain itemization.
                                    <br/>
                                    <br/>Offering unparalleled functionality, performance, and customizability, all encapsulated in a self-custodial C++ smart contract & deployable on any blockchain utilizing Antelope Leap.
                                    <br/>
                                    <br/>As the Web3 gaming landscape evolves, DoyenaCore emerges not merely as an asset standard, but as an on-chain loot engine.
                                </div>
                            </div>
                        </div>
                        : ""}
                        <div className="page-content-box-banner2" style={{backgroundImage:`url(${require('../images/DoyenaCoreSpace.jpg')})`}}>
                            <div className="page-content-box-banner-box2" >
                                <div className="page-content-text" style={{fontSize:"clamp(32px, 3.66vh, 3.66vw)", backdropFilter:"blur(0px)"}}>
                                    DoyenaCore,
                                    <br/>Asset Standard
                                </div>
                                <div style={{paddingBlock:"1.25rem"}}/>
                                <div className="page-content-text" style={{fontSize:"clamp(18px, 2vh, 2vw)", letterSpacing:"1vw", backdropFilter:"blur(0px)", minWidth:"200px", maxWidth:"65%"}}>Web3++
                                </div>
                                <div style={{paddingBlock:"1.5rem"}}/>
                                <div className="navbar-logo">
                                    <div className="navbar-links" onClick={()=>window.open("https://github.com/doyen-games/doyena-core-grant")}>Coming Soon</div>
                                </div>
                                {viewBox.width < 1000 ?
                                    <div className="page-content-box" style={{minWidth: "326px", marginTop:"3rem"}}>
                                        <div className="page-content-text" style={{textAlign:"center", fontSize:"clamp(22px, 2.4vh, 2.4vw)", fontWeight:"600", backdropFilter:"blur(0px)"}}>
                                            <div style={{padding:"0.3rem"}}>
                                            Self Custodial Deployment
                                            <br/>
                                            <br/>Native Support for Socketing NFTs
                                            <br/>
                                            <br/>Generative NFTs for less than $0.01
                                            <div style={{textAlign:"left", fontSize:"clamp(18px, 2vh, 2vw)", minWidth:"100%",fontWeight:"500", fontFamily:"Roboto", padding:"0.5rem"}}>
                                                <br/><br/>
                                                DoyenaCore is an open-source asset standard tailored for Web3 game studios seeking a flexible & feature rich solution to on-chain itemization.
                                                <br/>
                                                <br/>Offering unparalleled functionality, performance, and customizability, all encapsulated in a self-custodial C++ smart contract & deployable on any blockchain utilizing Antelope Leap.
                                                <br/>
                                                <br/>As the Web3 gaming landscape evolves, DoyenaCore emerges not merely as an asset standard, but as an on-chain loot engine.
                                            </div>
                                        </div>
                                    </div>
                                </div>:""}
                            </div>
                        </div>
                    </div> */}
                    {viewBox.width > 1000 && <div style={{marginBottom:"80px"}}/>}
                    <div className="page-content-box-container2" id="ide">
                        {viewBox.width > 1000 ?
                        <div className="page-content-box2">
                            <div className="page-content-text" style={{textAlign:"left", fontSize:"clamp(24px, 2.66vh, 2.66vw)", minWidth:"100%", marginLeft:"32px", fontWeight:"600"}}>
                                Create & Deploy Smart Contracts
                                <br/>
                                <br/>Directly in your Browser
                                <br/>
                                <br/>No Download Necessary
                                <div style={{textAlign:"left", fontSize:"clamp(18px, 2vh, 2vw)", minWidth:"100%",fontWeight:"500", fontFamily:"Roboto"}}>
                                    <br/><br/>
                                    The Wax Web IDE is our attempt at making smart contract development more accessible for the Wax Community.
                                    <br/>
                                    <br/>Code in C++ from right out of your browser and then compile & deploy directly to the blockchain.
                                    <br/>
                                    <br/>Forget setting up Docker & dive in.
                                </div>
                            </div>
                        </div>
                        : ""}
                        <div className="page-content-box-banner2" style={{backgroundImage:`url(${require('../images/IDEBackground.png')})`}}>
                            <div className="page-content-box-banner-box2" >
                                <div className="page-content-text" style={{fontSize:"clamp(32px, 3.66vh, 3.66vw)", backdropFilter:"blur(1px)"}}>
                                    Wax Web IDE
                                </div>
                                <div style={{paddingBlock:"1.25rem"}}/>
                                <div className="page-content-text" style={{fontSize:"clamp(18px, 2vh, 2vw)", letterSpacing:"0.5vw", backdropFilter:"blur(1px)", minWidth:"200px", maxWidth:"65%"}}>ezpz
                                </div>
                                <div style={{paddingBlock:"1.5rem"}}/>
                                <div className="navbar-logo">
                                    <div className="navbar-links" onClick={()=>window.open("https://ide.doyen.gg")}>Try Now</div>
                                </div>
                                {viewBox.width < 1000 ?
                                    <div className="page-content-box" style={{minWidth: "326px", marginTop:"3rem"}}>
                                        <div className="page-content-text" style={{textAlign:"center", fontSize:"clamp(22px, 2.4vh, 2.4vw)", fontWeight:"600", backdropFilter:"blur(0px)"}}>
                                            <div style={{padding:"0.3rem"}}>
                                            Create & Deploy Smart Contracts
                                            <br/>
                                            <br/>Directly in your Browser
                                            <br/>
                                            <br/>No Download Necessary
                                            <div style={{textAlign:"left", fontSize:"clamp(18px, 2vh, 2vw)", minWidth:"100%",fontWeight:"500", fontFamily:"Roboto", padding:"0.5rem"}}>
                                                <br/><br/>
                                                The Wax Web IDE is our attempt at making smart contract development more accessible for the Wax Community.
                                                <br/>
                                                <br/>Code in C++ from right out of your browser and then compile & deploy directly to the blockchain.
                                                <br/>
                                                <br/>Forget setting up Docker & dive in.
                                            </div>
                                        </div>
                                    </div>
                                </div>:""}
                            </div>
                        </div>
                    </div>
                </motion.div>
            </div>
            <div className="page-content-container" style={{minHeight:"100vh"}} id="contact">
                <div className="page-content">
                    <motion.div initial={{opacity:0}} animate={{opacity: entered ? [0,1] : 0}} transition={{delay:0.5, duration:1.5, ease:"linear"}}
                        className="page-content-header">
                        Start your Web3 journey today
                    </motion.div>
                    <div style={{paddingBlock:"var(--space-m)"}}/>
                    <div className="navbar-logo" onClick={()=>window.open("https://forms.gle/rKrdJVpzfvgXqmmt7")}>
                        <div className="navbar-links">Contact</div>
                    </div>
                </div>
            </div>



        </div>
    </>)
}