import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import "../css/navigation.css";

export function NavigationMenu(props){
    const {navbarLinks, handleNavigation} = props;

    const [open, setOpen] = useState(false);



    return (
    <>
        {!open ?
        <div className="navbar-logo" style={{marginRight:"16px"}} onClick={()=>setOpen(true)}>
            <div className="navbar-links">{"<Menu/>"}</div>
        </div>
        :
        <>
        <motion.div className="navbar-menu-background-fix" initial={{opacity: 0}} animate={{opacity: open ? [0,1] : 0}} transition={{delay: 0, ease:"linear", duration:0.5}}/>
        <div className="navbar-logo" style={{marginRight:"16px", zIndex:"2"}} onClick={()=>setOpen(false)}>
        <div className="navbar-links">{"<Close/>"}</div>
        </div>
        <motion.div initial={{opacity:0}} animate={{opacity: open ? [0,1] : 0}} transition={{delay: 0, ease:"linear", duration:0.5}}
        className="navbar-menu-container">
            <div style={{marginTop:"3rem"}}/>

            {navbarLinks.map((link, a)=>(
                <motion.div className="navbar-logo" style={{margin: "12px 0px 12px 0px", width:"89%"}} key={a} index={a}
                initial={{opacity:0}} animate={{opacity: open ? [0,1] : 0}} transition={{delay: 0.15 + (a * 0.08), ease:"linear", duration:0.5}}
                onClick={()=>{setOpen(false);  handleNavigation(`${link.nav}`);}}
                >
                    <div className="navbar-links">{"<"}{link.name}{"/>"}</div>
                </motion.div>
            ))}
                <motion.div className="navbar-logo" style={{margin: "12px 0px 12px 0px", width:"89%"}}
                initial={{opacity:0}} animate={{opacity: open ? [0,1] : 0}} transition={{delay: 0.15 + (navbarLinks.length * 0.08), ease:"linear", duration:0.5}}
                onClick={()=>{setOpen(false); window.open("https://github.com/doyen-games");}}
                >
                    <div className="navbar-links">{"<Github/>"}</div>
                </motion.div>
        </motion.div>
        </>


        }
    </>

    )
}