import React from "react";
import { Landing } from "../pages/landing.js";


export function routes({

}) {
    return [
    {
        path: "/",
        element:
        <Landing/>
    }
]
}