import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

export function Footer(props){
    const { handleNavigation } = props;

    const footerObj = [

        {name:"Contact", links:[
            {name:"Support", nav:"https://forms.gle/rKrdJVpzfvgXqmmt7", type:1},
            {name:"Twitter", nav:"https://twitter.com/DoyenGames", type:1},
            {name:"Discord", nav:"https://discord.gg/terraformers", type:1},

        ]},
        {name:"Tech", links:[
            {name:"Github", nav:"https://github.com/doyen-games", type:1}
        ]},
        {name:"Projects", links:[
            {name:"View All Projects", nav:"#projects", type:0},
            {name:"Terraformers, Many Worlds", nav:"https://tfmw.gg", type:1},
            {name:"Wax Web IDE", nav:"https://ide.doyen.gg", type:1}
        ]},

    ]

    const [imprint, setImprint] = useState(false);

    return (<>
    <div className="footer-container">
        <div className="footer">
            <div className="footer-content">
                {/* <img className="navbar-symbol-image" style={{minHeight:"96px", marginLeft:"24px"}}
                src={require('../images/Final Doyen Games Symbol Navbar Small.svg').default}/> */}
                <div className="footer-links-header" style={{display:"flex", flexDirection:"row", fontSize:"60px", fontWeight:"500", marginLeft:"24px"}}>
                <div>{"<D"}</div><div style={{marginLeft:"-11px"}}>{"G/>"}</div></div>
               <div className="footer-links" onClick={()=>setImprint(true)}>Imprint</div>
               <div className="footer-links" onClick={()=>setImprint(true)}>© Doyen Games Inc. 2024</div>
            </div>

            {footerObj.map((footer, a)=>(
                <div className="footer-content" style={{marginTop:"16px"}} key={a} index={a}>
                    <div className="footer-links-header">{footer.name}</div>
                    {footer.links.map((linka, b)=>(
                        <div className="footer-links" onClick={linka.type === 0 ? ()=>handleNavigation(linka.nav) : ()=>window.open(`${linka.nav}`)} key={b} index={b}>{linka.name}</div>
                    ))}
                </div>

            ))}


        </div>
    </div>
    {!imprint ? "" :
    <>
        <motion.div className="navbar-menu-background-fix" initial={{opacity: 0}} animate={{opacity: imprint ? [0,1] : 0}} transition={{delay: 0, ease:"linear", duration:0.5}} style={{zIndex:"10"}}/>
        <motion.div className="footer-imprint-house"  initial={{opacity:0}} animate={{opacity: imprint ? [0,1] : 0}} transition={{delay: 0, ease:"linear", duration:0.5}}>
            <div className="footer-imprint-container">
                <div className="footer-imprint-close" onClick={()=>setImprint(false)}>X</div>

                <div className="footer-imprint-text-header">Imprint</div>

                <div className="footer-imprint-text-title">Doyen Games Inc.</div>
                <div className="footer-imprint-text">1511 Tharrat Rd.</div>
                <div className="footer-imprint-text">V0R 2W3, Canada, BC</div>
                <div className="footer-imprint-text">Business Number: #1239192-9</div>
                <div style={{marginBottom:"20px"}}/>

                <div className="footer-imprint-text-title">Managing Director</div>
                <div className="footer-imprint-text">Kas Hashemi</div>
                <div style={{marginBottom:"20px"}}/>

                <div className="footer-imprint-text-title">Contact</div>
                <div className="footer-imprint-text">Doyen Games: contact@doyen.gg</div>
                <div className="footer-imprint-text">TFMW: contact@tfmw.gg</div>



            </div>
        </motion.div>
    </>}
    </>)


}